import './App.css';
import {
    EditGuesser,
    FieldGuesser,
    HydraAdmin,
    InputGuesser,
    ListGuesser,
    ResourceGuesser,
    ShowGuesser
} from "@api-platform/admin";
import {Layout, Resource, useDataProvider, useGetOne, useRecordContext} from 'react-admin';
import {ReactQueryDevtools} from 'react-query/devtools';
import React from 'react'
import authProvider from './utils/authProvider.ts';
import {dataProvider, fetchHydra} from "./utils/dataProviderHydra";
import {i18nProvider} from "./i18n";
import {ENTRYPOINT} from './config';
import useBreadcrumbs from "use-react-router-breadcrumbs";

import Dashboard from './Dashboard';
import {CommunauteList, CommunauteEdit, CommunauteShow, CommunauteCreate} from "./pages/communautes";
import {
    PublicationList,
    PublicationEdit,
    PublicationShow,
    PublicationCreate,
    EmbedPublicationList
} from "./pages/publications";
import {UtilisateurList, UtilisateurEdit, UtilisateurShow} from "./pages/utilisateurs";
import {UtilisateurDemandeList, UtilisateurDemandeShow, UtilisateurDemandeEdit} from "./pages/utilisateur_demandes";
import {DroitList, DroitEdit, DroitShow, DroitCreate} from "./pages/droits";
import {EquipeList, EquipeCreate, EquipeEdit, EquipeShow} from "./pages/equipes";
import {OffreEmploiList, OffreEmploiEdit, OffreEmploiShow, OffreEmploiCreate} from "./pages/offreEmploi";
import {CseInfoList, CseInfoEdit, CseInfoShow, CseInfoCreate} from "./pages/cseInfos";
import {OffreEmploiMediaList, OffreEmploiMediaCreate} from "./pages/offreEmploiMedia";
import {IndicateurList, IndicateurCreate, IndicateurEdit, IndicateurShow} from "./pages/indicateurs";
import {DeviceCreate, DeviceEdit, DeviceList, DeviceShow} from "./pages/devices";
import {CibleList, CibleEdit, CibleShow, CibleCreate} from "./pages/cibles";
import {SignalementList, SignalementCreate, SignalementEdit} from "./pages/signalements";
import {SignalementMediaList, SignalementMediaCreate, SignalementMediaShow} from "./pages/signalementMedias";
import {
    SignalementCategorieList,
    SignalementCategorieCreate,
    SignalementCategorieEdit
} from "./pages/signalementCategories";
import {
    PublicationMediaList,
    PublicationMediaCreate/*, PublicationMediaEdit*/,
    PublicationMediaShow
} from "./pages/publication_media";
import {EvenementList, EvenementCreate, EvenementEdit, EvenementShow} from "./pages/evenements";
import {PaysList, PaysShow, PaysCreate, PaysEdit} from "./pages/pays";
import {NavLink, Route, useLocation} from "react-router-dom";
import {DemandeAdhesionEdit, DemandeAdhesionList, DemandeAdhesionShow} from "./pages/demandeAdhesions";
import {SondageCategorieEdit, SondageCategorieList, SondageCategorieShow} from "./pages/sondageCategories";
import {SondageCreate, SondageEdit, SondageList, SondageShow} from "./pages/sondage";
import {SondageMediaCreate, SondageMediaList, SondageMediaShow} from "./pages/sondageMedia";
import {SondageReponseList, SondageReponseShow} from "./pages/SondageReponse";
import {BoiteAIdeeCreate, BoiteAIdeeEdit, BoiteAIdeeList, BoiteAIdeeShow} from "./pages/boiteAIdee";
import {ApplicationCreate, ApplicationEdit, ApplicationList, ApplicationShow} from "./pages/application";


const myDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        return dataProvider.create(resource, {
            data: {
                ...params.data,
            },
        });
        //}).then((response) => {
        // if (resource === 'publications' && params.data.medias !== undefined) {
        //     const newPictures = params.data.medias.filter(
        //         p => p.rawFile instanceof File
        //     );
        //
        //     newPictures.forEach(media => {
        //         if (media.rawFile instanceof File) {
        //
        //             let formData = new FormData();
        //             formData.append('file', media.rawFile);
        //             formData.append('filename', media.rawFile.name);
        //             formData.append('publication', response.id);
        //
        //             fetchHydra(new URL(`${ENTRYPOINT}/publication_media`), {
        //                 method: 'POST',
        //                 body: formData,
        //             }).then(({ json }) => ({
        //                 data: { ...params.data, id: json.id },
        //             }));
        //         }
        //     });
        // }
        //});
    },
    update: (resource, params) => {
        if (resource === 'publications' && (params.data.medias !== undefined || params.data.couvertureFile !== undefined)) {
            if (params.data.medias !== undefined) {
                const newPictures = params.data.medias.filter(
                    p => p.rawFile instanceof File
                );

                newPictures.forEach(media => {
                    if (media.rawFile instanceof File) {

                        let formData = new FormData();
                        formData.append('file', media.rawFile);
                        formData.append('filename', media.rawFile.name);
                        formData.append('publication', params.data.id);

                        fetchHydra(new URL(`${ENTRYPOINT}/publication_media`), {
                            method: 'POST',
                            body: formData,
                        }).then(({json}) => ({
                            data: {...params.data, id: json.id},
                        }));
                    }
                });
            }
            if (params.data.couvertureFile !== undefined) {
                const newCouvertureFile = params.data.couvertureFile.rawFile;
                if (params.data.couvertureFile.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('couvertureFile', newCouvertureFile);
                    formData.append('couvertureFilename', newCouvertureFile.name);
                    formData.append('publication_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/publications/${params.data.originId}/change_couverture`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                    params.data.couvertureFile = null;
                }
            }

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'evenements' && (params.data.medias !== undefined || params.data.couvertureFile !== undefined)) {
            if (params.data.medias !== undefined) {
                const newPictures = params.data.medias.filter(
                    p => p.rawFile instanceof File
                );

                newPictures.forEach(media => {
                    if (media.rawFile instanceof File) {

                        let formData = new FormData();
                        formData.append('file', media.rawFile);
                        formData.append('filename', media.rawFile.name);
                        formData.append('evenement', params.data.id);

                        fetchHydra(new URL(`${ENTRYPOINT}/evenement_media`), {
                            method: 'POST',
                            body: formData,
                        }).then(({json}) => ({
                            data: {...params.data, id: json.id},
                        }));
                    }
                });
            }
            if (params.data.couvertureFile !== undefined) {
                const newCouvertureFile = params.data.couvertureFile.rawFile;
                if (params.data.couvertureFile.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('couvertureFile', newCouvertureFile);
                    formData.append('couvertureFilename', newCouvertureFile.name);
                    formData.append('evenement_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/evenements/${params.data.originId}/change_couverture`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                    params.data.couvertureFile = null;
                }
            }

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'signalements' && params.data.medias !== undefined) {
            const newPictures = params.data.medias.filter(
                p => p.rawFile instanceof File
            );

            newPictures.forEach(media => {
                if (media.rawFile instanceof File) {

                    let formData = new FormData();
                    formData.append('file', media.rawFile);
                    formData.append('filename', media.rawFile.name);
                    formData.append('signalement', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/signalement_media`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                }
            });

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'offre_emplois' && (params.data.medias !== undefined || params.data.couvertureFile !== undefined)) {

            if (params.data.medias !== undefined) {
                const newPictures = params.data.medias.filter(
                    p => p.rawFile instanceof File
                );

                newPictures.forEach(media => {
                    if (media.rawFile instanceof File) {

                        let formData = new FormData();
                        formData.append('file', media.rawFile);
                        formData.append('filename', media.rawFile.name);
                        formData.append('offreEmploi', params.data.id);

                        fetchHydra(new URL(`${ENTRYPOINT}/offre_emploi_media`), {
                            method: 'POST',
                            body: formData,
                        }).then(({json}) => ({
                            data: {...params.data, id: json.id},
                        }));
                    }
                });
            }
            if (params.data.couvertureFile !== undefined) {
                const newCouvertureFile = params.data.couvertureFile.rawFile;
                if (params.data.couvertureFile.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('couvertureFile', newCouvertureFile);
                    formData.append('couvertureFilename', newCouvertureFile.name);
                    formData.append('offre_emploi_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/offre_emplois/${params.data.originId}/change_couverture`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                    params.data.couvertureFile = null;
                }
            }

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'sondages' && params.data.media !== undefined) {
            const newPictures = params.data.media.filter(
                p => p.rawFile instanceof File
            );

            newPictures.forEach(media => {
                if (media.rawFile instanceof File) {

                    let formData = new FormData();
                    formData.append('file', media.rawFile);
                    formData.append('filename', media.rawFile.name);
                    formData.append('sondage', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/sondage_media`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                }
            });

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'utilisateurs' && (params.data.file !== undefined || params.data.couvertureFile !== undefined)) {
            console.log(resource === 'utilisateurs' && (params.data.file !== undefined || params.data.couvertureFile !== undefined));
            console.log(params.data.couvertureFile);
            if (params.data.file !== undefined) {
                const newAvatarFile = params.data.file.rawFile;
                if (params.data.file.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('file', newAvatarFile);
                    formData.append('filename', newAvatarFile.name);
                    formData.append('utilisateur_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/utilisateurs/${params.data.originId}/change_avatar`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));

                    params.data.file = null;
                }
            }
            if (params.data.couvertureFile !== undefined) {
                const newCouvertureFile = params.data.couvertureFile.rawFile;
                if (params.data.couvertureFile.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('couvertureFile', newCouvertureFile);
                    formData.append('couvertureFilename', newCouvertureFile.name);
                    formData.append('utilisateur_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/utilisateurs/${params.data.originId}/change_couverture`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));

                    params.data.couvertureFile = null;
                }
            }
            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if (resource === 'communautes' && (params.data.file !== undefined || params.data.couvertureFile !== undefined)) {
            if (params.data.file !== undefined) {
                const newLogoFile = params.data.file.rawFile;

                if (params.data.file.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('file', newLogoFile);
                    formData.append('filename', newLogoFile.name);
                    formData.append('communaute_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/communautes/${params.data.originId}/change_logo`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));

                    params.data.file = null;
                }
            }
            if (params.data.couvertureFile !== undefined) {
                const newCouvertureFile = params.data.couvertureFile.rawFile;
                if (params.data.couvertureFile.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('couvertureFile', newCouvertureFile);
                    formData.append('couvertureFilename', newCouvertureFile.name);
                    formData.append('communaute_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/communautes/${params.data.originId}/change_couverture`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));
                    params.data.couvertureFile = null;
                }
            }
            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }

        if(resource === "utilisateur_demandes"){
            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                    id : parseInt(params.data.id.split('/').pop())
                },
            });
        }

        if (resource === 'applications' && (params.data.file !== undefined)) {

                const newLogoFile = params.data.file.rawFile;

                if (params.data.file.rawFile instanceof File) {
                    let formData = new FormData();
                    formData.append('file', newLogoFile);
                    formData.append('filename', newLogoFile.name);
                    formData.append('application_id', params.data.id);

                    fetchHydra(new URL(`${ENTRYPOINT}/applications/${params.data.originId}/change_logo`), {
                        method: 'POST',
                        body: formData,
                    }).then(({json}) => ({
                        data: {...params.data, id: json.id},
                    }));

                    params.data.file = null;
                }

            return dataProvider.update(resource, {
                id: params.data.id,
                data: {
                    ...params.data,
                },
            });
        }


        return dataProvider.update(resource, params)
    .catch(error => {
            // Gérer l'erreur ici
            console.error('Erreur lors de la création :', error);
            throw error; // Propager l'erreur pour qu'elle soit gérée par le composant React Admin
        });
    },
};


//
// const PaysList = props => (
//     <ListGuesser {...props}>
//         <FieldGuesser source="code" />
//     </ListGuesser>
// );

const GetOne = (resource, id) => {
    const dataProvider = useDataProvider();
    const {data, isLoading, error, refetch} = useGetOne(resource, {id: `/api/${resource}/${id}`});

    if (isLoading) {
        return <span>Chargement</span>;
    }
    if (error) {
        return <span>ERROR</span>;
    }

    //console.log(data);


    if (data['name'] !== undefined && typeof data.name === 'string') {
        //console.log('name');
        return <span>{data.name}</span>;
    }

    if (data['titre'] !== undefined && typeof data.titre === 'string') {
        //console.log('titre');
        return <span>{data.titre}</span>;
    }

    if (data['libelle'] !== undefined && typeof data.libelle === 'string') {
        //console.log('libelle');
        return <span>{data.libelle}</span>;
    }

    if (data['nom'] !== undefined && typeof data.nom === 'string') {
        //console.log('nom');
        return <span>{data.nom}</span>;
    }

    if (data['libelle'] !== undefined && typeof data.libelle === 'string') {
        console.log('libelle');
        return <span>{data.libelle}</span>;
    }

    if (data.name !== undefined && typeof data.name === 'string') {
        console.log('name');
        return <span>{data.name}</span>;
    }
    return <span>{id}</span>;
}

const Breadcrumb = () => {
    const breadcrumbs = useBreadcrumbs();
    return (
        <nav>
            {breadcrumbs.map(({match, breadcrumb, location}, index) => {
                var separators = ['%2F', '/'];
                const words = breadcrumb['key'].split(new RegExp(separators.join('|'), 'g')).filter((str) => str !== '');

                if (words.length === 4) {
                    const resource = words[2];
                    const id = words[3];

                    const retour = GetOne(resource, id);

                    return (
                        <span key={match.pathname}>
                            <NavLink
                                key={match.pathname}
                                to={`${match.pathname}/show`}
                                className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}
                            >
                            {retour}
                            </NavLink>
                            {index !== breadcrumbs.length - 1 && <span className="breadcrumb-arrow">›</span>}
                        </span>
                    );
                }
                return (
                    <span key={match.pathname}>
                        <NavLink
                            key={match.pathname}
                            to={match.pathname}
                            className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            {breadcrumb}
                        </NavLink>
                        {index !== breadcrumbs.length - 1 && <span className="breadcrumb-arrow">›</span>}
                    </span>
                )
            })}
        </nav>
    );
};

export const MyLayout = ({children, dashboard, props}) => (
    <>
        <Layout  {...props}>
            <Breadcrumb/>
            {children}
        </Layout>
        <ReactQueryDevtools initialIsOpen={false}/>
    </>
);

//export default App;
export default () => (
    <HydraAdmin
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={myDataProvider}
        entrypoint={ENTRYPOINT}
        i18nProvider={i18nProvider}
        layout={MyLayout}
    >
        <ResourceGuesser
            name="applications"
            list={ApplicationList}
            edit={ApplicationEdit}
            show={ApplicationShow}
            create={ApplicationCreate}
        />
        <Resource
            name="communautes"
            list={CommunauteList}
            edit={CommunauteEdit}
            show={CommunauteShow}
            create={CommunauteCreate}
            recordRepresentation={(record) => `${record.titre}`}>
            <Route path=":communauteId/publications" element={<EmbedPublicationList/>}/>
            <Route path=":communauteId/publications/:publicationId" element={<PublicationShow/>}/>
            <Route path=":communauteId/signalements" element={<SignalementList/>}/>
            <Route path=":communauteId/signalements/:id" element={<SignalementEdit/>}/>
            <Route path=":communauteId/offre_emplois" element={<OffreEmploiList/>}/>
            <Route path=":communauteId/cse_infos" element={<CseInfoList/>}/>
            <Route path=":communauteId/evenements" element={<EvenementList/>}/>
            <Route path=":communauteId/indicateurs" element={<IndicateurList/>}/>
            <Route path=":communauteId/droits" element={<DroitList/>}/>
            <Route path=":communauteId/equipes" element={<EquipeList/>}/>
            <Route path=":communauteId/sondages" element={<SondageList/>}/>
        </Resource>

        <Resource
            name="equipes"
            list={EquipeList}
            create={EquipeCreate}
            edit={EquipeEdit}
            show={EquipeShow}
            recordRepresentation={(record) => `${record.nom}`}
        />

        <Resource
            name="publications"
            list={PublicationList}
            edit={PublicationEdit}
            show={PublicationShow}
            create={PublicationCreate}
            recordRepresentation={(record) => `${record.titre}`}
        />

        <ResourceGuesser
            name="publication_media"
            list={PublicationMediaList}
            /*edit={PublicationMediaEdit}*/
            show={PublicationMediaShow}
            create={PublicationMediaCreate}
        />

        <Resource
            name={"evenements"}
            list={EvenementList}
            create={EvenementCreate}
            edit={EvenementEdit}
            show={EvenementShow}
            recordRepresentation={(record) => `${record.titre}`}
        />

        <ResourceGuesser
            name={"evenement_categories"}
            recordRepresentation={(record) => `${record.libelle}`}
        />
        <ResourceGuesser name={"evenement_media"}/>

        <ResourceGuesser
            name="droits"
            list={DroitList}
            edit={DroitEdit}
            show={DroitShow}
            create={DroitCreate}
            options={{label: 'Membres'}}
        />
        <ResourceGuesser
            name="devices"
            list={DeviceList}
            edit={DeviceEdit}
            show={DeviceShow}
            create={DeviceCreate}
        />
        {/*<ResourceGuesser name="pays" list={PaysList} />*/}
        <ResourceGuesser
            name="pays"
            list={PaysList}
            show={PaysShow}
            edit={PaysEdit}
            create={PaysCreate}
        />
        <ResourceGuesser
            name="cibles"
            list={CibleList}
            edit={CibleEdit}
            show={CibleShow}
            create={CibleCreate}
        />
        <ResourceGuesser
            name="indicateurs"
            list={IndicateurList}
            create={IndicateurCreate}
            edit={IndicateurEdit}
            show={IndicateurShow}
        />
        <Resource
            name="offre_emplois"
            list={OffreEmploiList}
            edit={OffreEmploiEdit}
            show={OffreEmploiShow}
            create={OffreEmploiCreate}
            recordRepresentation={(record) => `${record.titre}`}
        />
        <Resource
            name="cse_infos"
            list={CseInfoList}
            edit={CseInfoEdit}
            show={CseInfoShow}
            create={CseInfoCreate}
            recordRepresentation={(record) => `${record.titre}`}
        />
        <ResourceGuesser
            name="offre_emploi_media"
            list={OffreEmploiMediaList}
            create={OffreEmploiMediaCreate}
        />
        <ResourceGuesser
            name="signalements"
            list={SignalementList}
            create={SignalementCreate}
            edit={SignalementEdit}
        />
        <ResourceGuesser
            name="signalement_media"
            list={SignalementMediaList}
            create={SignalementMediaCreate}
            show={SignalementMediaShow}
        />
        <Resource
            name="signalement_categories"
            list={SignalementCategorieList}
            create={SignalementCategorieCreate}
            edit={SignalementCategorieEdit}
            recordRepresentation={(record) => `${record.titre}`}
        />
        <ResourceGuesser
            name="utilisateur_demandes"
            list={UtilisateurDemandeList}
            edit={UtilisateurDemandeEdit}
            show={UtilisateurDemandeShow}
        />
        <Resource
            name="utilisateurs"
            list={UtilisateurList}
            edit={UtilisateurEdit}
            show={UtilisateurShow}
            recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
            name="demande_adhesions"
            list={DemandeAdhesionList}
            edit={DemandeAdhesionEdit}
            show={DemandeAdhesionShow}
            recordRepresentation={(record) => `${record.prenom} ${record.nom}`}
        />
        <Resource
            name="sondage_categories"
            list={SondageCategorieList}
            edit={SondageCategorieEdit}
            show={SondageCategorieShow}
            recordRepresentation={(record) => `${record.titre}`}
        />
        <Resource name="sondages"
                  list={SondageList}
                  show={SondageShow}
                  edit={SondageEdit}
                  create={SondageCreate}
                  recordRepresentation={(record) => ` - ${record.titre}`}/>

        <Resource name={'sondage_reponses'}
                  list={SondageReponseList}
                  show={SondageReponseShow}
        />

        <ResourceGuesser
            name="sondage_media"
            list={SondageMediaList}
            create={SondageMediaCreate}
            show={SondageMediaShow}/>

        <ResourceGuesser
            name="boite_a_idees"
            list={BoiteAIdeeList}
            create={BoiteAIdeeCreate}
            edit={BoiteAIdeeEdit}
            show={BoiteAIdeeShow}
        />

    </HydraAdmin>
);

import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    EditButton,
    ShowButton,
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    BooleanInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    TabbedShowLayout,
    ArrayField, FunctionField, Show, ImageField, useRefresh, FormDataConsumer, ImageInput,
} from 'react-admin';
import React from "react";
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {useMutation} from "react-query";
import {ColorField, ColorInput} from "react-admin-color-picker";

const ApplicationListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const filters = [
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>
];


const applicationFilters = [
    <TextInput source={"nom"} />,
    <TextInput source={"code"} />,
    <TextInput source={"nomDomaine"} />,
    <TextInput source={"iosBundleId"} />,
    <TextInput source={"androidNomPackage"} />,
];

export const ApplicationList = props => (
    <List {...props}
          actions={<ApplicationListActions />}
          filters={applicationFilters}>
        <DatagridConfigurable rowClick={"show"}>
            <FieldGuesser source={"nom"} />
            <FieldGuesser source={"code"} />
            <FieldGuesser source={"nomDomaine"} />
            <FieldGuesser source={"iosBundleId"} />
            <FieldGuesser source={"androidNomPackage"} />
            <FieldGuesser source={"email"} />
            <ShowButton />
            <EditButton />
        </DatagridConfigurable>
    </List>
);

export const ApplicationShow = props => (
    <Show {...props}>
        <MyApplicationShow/>
    </Show>
);

const MyApplicationShow = props => {
    const record = useRecordContext();
    if (!record) return null;
    return(
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Application">
                    <FieldGuesser source={"nom"} />
                    <FieldGuesser source={"code"} />
                    <FieldGuesser source={"nomDomaine"} />
                    <FieldGuesser source={"iosBundleId"} />
                    <FieldGuesser source={"androidNomPackage"} />
                    <FieldGuesser source={"email"} />
                    <ColorField source={"couleurPrimaire"}/>
                    <ImageField sx={{mb: 3}} source={"logo"}/>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Communauntés">
                <CommunautesField source="communautes"/>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>

    );
}

const CommunautesField = props => {
    return (
        <ArrayField {...props}>
            <Datagrid bulkActionButtons={false} isRowSelectable={()=> false}>
                <TextField
                    source="titre"/>
                <TextField source="adresse"/>
                <TextField source="codePostal"/>
                <TextField source="ville"/>
            </Datagrid>
        </ArrayField>
    )
}

export const ApplicationEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"nom"} helperText={"Le nom de l'application. Celui-ci sera notamment utilisé dans les emails."}/>
        <InputGuesser source={"code"} helperText={"Le code de l'application sera celui qui fera le lien avec le X-APP qui dans le header des requêtes envoyés par l'application. Le code de l'application doit être en snakeCase"} placeholder={"codeApplication"}/>
        <InputGuesser source={"nomDomaine"} helperText={"Le nom de domaine qui pointe vers l'application version web. Il est utilisé dans les emails."} />
        <InputGuesser source={"iosBundleId"} helperText={"Le bundle Id l'application version iOS de l'application. Il est utilisé dans les emails."}/>
        <InputGuesser source={"androidNomPackage"} helperText={"Le nom du package de l'application version android. Il est utilisé dans les emails."}/>
        <InputGuesser source={"email"} helperText={"L'adresse mail renseignée sera utilisé comme expéditeur pour l'envoi des mails."}/>
        <ColorInput source={"couleurPrimaire"} picker="Sketch" helperText={"La couleur renseignée sera utilisé pour dynamiser le rendu des mails."}/>
        <ImageField source={"logo"}/>
        <FormDataConsumer>
            {({
                  formData,
                  scopedFormData,
                  getSource,
                  ...rest
              }) => (formData.logo !== null) ? <SupprimerLogoBouton/> : null}

        </FormDataConsumer>
        <ImageInput multiple={false}
                    name={"file"}
                    label="Changer de logo"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
    </EditGuesser>
);

export const ApplicationCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"nom"} helperText={"Le nom de l'application. Celui-ci sera notamment utilisé dans les emails."}/>
        <InputGuesser source={"code"} helperText={"Le code de l'application sera celui qui fera le lien avec le X-APP qui dans le header des requêtes envoyés par l'application. Le code de l'application doit être en snakeCase"} placeholder={"codeApplication"}/>
        <InputGuesser source={"nomDomaine"} helperText={"Le nom de domaine qui pointe vers l'application version web. Il est utilisé dans les emails."}/>
        <InputGuesser source={"iosBundleId"} helperText={"Le bundle Id l'application version iOS de l'application. Il est utilisé dans les emails."}/>
        <InputGuesser source={"androidNomPackage"} helperText={"Le nom du package de l'application version android. Il est utilisé dans les emails."}/>
        <InputGuesser source={"email"} helperText={"L'adresse mail renseignée sera utilisé comme expéditeur pour l'envoi des mails."}/>
        <ColorInput source={"couleurPrimaire"} picker="Sketch" helperText={"La couleur renseignée sera utilisé pour dynamiser le rendu des mails."} />
        <Box sx={{ marginBottom: 4 }}></Box>
        <ImageInput multiple={false}
                    name={"file"}
                    label="Ajouter un logo. Il sera utilisé dans les emails"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
    </CreateGuesser>
);
const SupprimerLogoBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `applications`,
            {id: record.id, data: {deleteLogo: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );


    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer le logo</Button>;
};

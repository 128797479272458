import {AutocompleteInput, FormDataConsumer, ReferenceInput} from "react-admin";
import {useWatch} from "react-hook-form";
import React from "react";

export const UtilisateurInput = (props) => (
    <ReferenceInput reference="utilisateurs" name={"Auteur"} {...props}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
            name={"auteur"}/>
    </ReferenceInput>
)


export const CommunauteInput = props => (
    <ReferenceInput reference="communautes" name={"Communaute"} {...props}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"} {...props} />
    </ReferenceInput>
);

export const CibleInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceInput reference="cibles" name={"Cible"} filter={{communaute: communaute}} {...props}>
                    <AutocompleteInput
                        optionText={(choice) =>
                            choice?.id // the empty choice is { id: '' }
                                ? `${choice.name}`
                                : ''
                        }
                        name={"cible"} {...props} />
                </ReferenceInput>
            }
        </FormDataConsumer>
    );
}

export const ApplicationInput = props => (
    <ReferenceInput reference="applications" name={"Application"} {...props}>
        <AutocompleteInput
            isRequired={true}
            label={"Application"}
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.nom}`
                    : ''
            }
            name={"application"} {...props} />
    </ReferenceInput>
);